import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import { matchSorter } from "match-sorter";

import SingleTrades from "../components/tables/SingleTrades";
import SingleTradesMobile from "../components/tables/SingleTradesMobile";
import TableTabs from "../components/TableTabs";
import TableFilter from "../components/TableFilter";
import MobileTableFilter from "../components/MobileTableFilter";
import Edges from "../components/Edges";
import Seo from "../components/Seo";
import ProgramIntro from "../components/ProgramIntro";
import { getInactiveIntakes, getQuickFinderIntakes } from "../helpers/intake";

const ProgramTemplate = (props) => {
    const {
        pageContext,
        uri,
        location,
        data,
        data: {
            allInstitution,
            publicProgram,
            publicProgram: { publicProgramTypesJson, name, schedules, metaDesc },
        },
    } = props;

    const selectedLevel = location?.state?.selectedLevel;
    const selectedRegion = location?.state?.selectedRegion;
    const isQuickFinderSearch = location?.state?.isQuickFinderSearch;

    const tableHeaders = [{ title: "Institution" }, { title: "Campus" }, { title: "Date" }, { title: "Program" }, { title: "Contact Institution" }];
    // updating mobile table header titles will affect layout downstream
    const mobileTableHeaders = [{ title: "Institution" }, { title: "Campus" }, { title: "Date" }, { title: "Program" }, { title: "Contact" }];

    const [mobileFilter, toggleMobileFilter] = useState(false);
    // this is the program type selection (Apprentice, Foundation, etc)
    const [selectedSector, setSelectedSector] = useState(publicProgram.schedules[0].publicProgramTypeId);

    const [apprenticeLevel, setApprenticeLevel] = useState("");

    const [filterRegions, setFilteredRegions] = useState("");
    const [filteredRegionId, setFilteredRegionId] = useState(selectedRegion?.id ?? "");
    const [filterCampus, setFilterCampus] = useState("");

    const [filterDate, setFilterDate] = useState("");
    const [filterAvailability, setFilterAvailability] = useState(isQuickFinderSearch ? "Open" : "");
    const [filterFormatGapTraining, setFilterFormatGapTraining] = useState("");
    const [filterFormatCombinedDelivery, setFilterFormatCombinedDelivery] = useState("");
    const [filterFormatInSchool, setFilterFormatInSchool] = useState("");

    const [filteredResults, setFilteredResults] = useState(location?.state?.filteredResults || "");

    const filteredPublicPrograms = selectedSector
        ? matchSorter(publicProgram.schedules, selectedSector, {
              keys: ["publicProgramTypeId"],
          })
        : publicProgram.schedules;

    const filterProgramLevels = matchSorter(filteredPublicPrograms, apprenticeLevel ? apprenticeLevel : filteredResults, {
        keys: ["apprenticeLevel"],
    });

    const filterProperties = filterCampus + " " + filteredRegionId + " " + filterDate + " " + filterAvailability + " " + filterFormatCombinedDelivery + " " + filterFormatGapTraining + " " + filterFormatInSchool;

    const filteredApprenticeSchedules = Array.from(
        new Set(
            schedules?.map((schedule) => {
                return schedule.apprenticeLevel;
            }),
        ),
    );

    const apprenticeLevels = filteredApprenticeSchedules.filter((x) => x != null).sort();

    const handleSearch = (e) => {
        if (e.target.value) {
            setSelectedSector(e.target.value);
        } else {
            setSelectedSector(null);
        }
    };

    const toggleFilter = () => {
        toggleMobileFilter(!mobileFilter);
    };

    const filterMultipleWords = (
        rows, // array of data [{a: "a", b: "b"}, {a: "c", b: "d"}]
        keys, // keys to search ["a", "b"]
        filterValue: string, // potentially multi-word search string "two words"
    ) => {
        if (!filterValue || !filterValue.length) {
            return rows;
        }

        const terms = filterValue.split(" ");
        if (!terms) {
            return rows;
        }

        // reduceRight will mean sorting is done by score for the _first_ entered word.
        return terms.reduceRight((results, term) => matchSorter(results, term, { keys }), rows);
    };

    let filteredPrograms = filterMultipleWords(
        apprenticeLevel || filteredResults ? filterProgramLevels : filteredPublicPrograms,
        ["region.id", "campus.id", "startDate", "waitlistIndicator.status", "isGapTraining", "isCombinedDelivery", "isInSchool"],
        filterProperties,
    );

    //filter out inActive intakes for quick finder search
    if (isQuickFinderSearch === true) {
        filteredPrograms = filteredPrograms.filter((item) => item.isActive);
        filteredPrograms = getQuickFinderIntakes(filteredPrograms);
    }

    filteredPrograms.sort(function (a, b) {
        return new Date(a.startDate) - new Date(b.startDate);
    });

    const activeIntakes = filteredPrograms && filteredPrograms.filter((p) => p.isActive);
    const inActiveIntakes = getInactiveIntakes(filteredPrograms, activeIntakes);

    useEffect(() => {
        if (!selectedSector) return;

        if (selectedLevel) {
            setApprenticeLevel(selectedLevel);
            return;
        }

        if (selectedSector === "apprentice") {
            setApprenticeLevel(apprenticeLevels[0]);
        } else {
            setApprenticeLevel("");
        }
    }, [selectedSector, selectedLevel, uri]);

    return (
        <>
            <Seo title={name} description={metaDesc} />

            <ProgramIntro program={publicProgram} isQuickFinderSearch={isQuickFinderSearch} />

            <div className="flex flex-row justify-center bg-lightGray py-[50px]">
                <Edges size="2xl">
                    <div className="flex flex-row">
                        <div className="hidden md:block">
                            <TableFilter
                                data={data}
                                setSelectedSector={setSelectedSector}
                                setFilteredResults={setFilteredResults}
                                setFilteredRegions={setFilteredRegions}
                                setFilteredRegionId={setFilteredRegionId}
                                filteredRegionId={filteredRegionId}
                                setFilterCampus={setFilterCampus}
                                filterCampus={filterCampus}
                                setFilterDate={setFilterDate}
                                filterDate={filterDate}
                                setFilterFormatInSchool={setFilterFormatInSchool}
                                filterFormatInSchool={filterFormatInSchool}
                                setFilterAvailability={setFilterAvailability}
                                filterAvailability={filterAvailability}
                                filterFormatGapTraining={filterFormatGapTraining}
                                filterFormatCombinedDelivery={filterFormatCombinedDelivery}
                                setFilterFormatGapTraining={setFilterFormatGapTraining}
                                setFilterFormatCombinedDelivery={setFilterFormatCombinedDelivery}
                                setApprenticeLevel={setApprenticeLevel}
                                filteredSchedules={filteredPrograms}
                                activeIntakes={activeIntakes}
                                isQuickFinderSearch
                            />
                        </div>
                        {mobileFilter && (
                            <div className="fixed top-0 right-0 z-50 block md:hidden">
                                <MobileTableFilter
                                    data={data}
                                    setFilteredResults={setFilteredResults}
                                    setFilteredRegionId={setFilteredRegionId}
                                    filteredRegionId={filteredRegionId}
                                    setSelectedSector={setSelectedSector}
                                    setFilterFormatInSchool={setFilterFormatInSchool}
                                    filterFormatInSchool={filterFormatInSchool}
                                    setFilterCampus={setFilterCampus}
                                    filterCampus={filterCampus}
                                    filterFormatGapTraining={filterFormatGapTraining}
                                    filterFormatCombinedDelivery={filterFormatCombinedDelivery}
                                    setFilterDate={setFilterDate}
                                    filterDate={filterDate}
                                    setFilterAvailability={setFilterAvailability}
                                    filterAvailability={filterAvailability}
                                    setFilterFormatGapTraining={setFilterFormatGapTraining}
                                    setFilterFormatCombinedDelivery={setFilterFormatCombinedDelivery}
                                    setApprenticeLevel={setApprenticeLevel}
                                    toggleFilter={toggleFilter}
                                    mobileFilter={mobileFilter}
                                    toggleMobileFilter={toggleMobileFilter}
                                    filteredSchedules={filteredPrograms}
                                    activeIntakes={activeIntakes}
                                    isQuickFinderSearch
                                />
                            </div>
                        )}
                        <div className="mx-auto flex grow flex-col">
                            <TableTabs
                                uri={uri}
                                {...pageContext}
                                sectorPage={true}
                                tabs={publicProgramTypesJson}
                                location={location}
                                selectedSector={selectedSector}
                                onSearch={handleSearch}
                                schedules={schedules}
                                setFilteredResults={setFilteredResults}
                                filteredResults={filteredResults}
                                apprenticeLevel={apprenticeLevel}
                                setApprenticeLevel={setApprenticeLevel}
                                toggleFilter={toggleFilter}
                                filteredLevels={apprenticeLevels}
                                isQuickFinderSearch={isQuickFinderSearch}
                            />
                            <div className="mb-[25px] flex flex-col px-[10px] pb-[15px]">
                                <div className="hidden md:block ">
                                    <SingleTrades
                                        programName={name}
                                        allInstitution={allInstitution.nodes}
                                        activeIntakes={activeIntakes}
                                        inActiveIntakes={inActiveIntakes}
                                        uri={uri}
                                        headers={tableHeaders}
                                        schedulesMetaData={pageContext.schedulesMetaData}
                                        selectedSector={selectedSector}
                                    />
                                </div>
                                <div className="block md:hidden ">
                                    <SingleTradesMobile
                                        programName={name}
                                        allInstitution={allInstitution.nodes}
                                        activeIntakes={activeIntakes}
                                        inActiveIntakes={inActiveIntakes}
                                        uri={uri}
                                        headers={mobileTableHeaders}
                                        schedulesMetaData={pageContext.schedulesMetaData}
                                        selectedSector={selectedSector}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Edges>
            </div>
        </>
    );
};

export default ProgramTemplate;

export const pageQuery = graphql`
    query Program($id: String!) {
        publicProgram(id: { eq: $id }) {
            metaDesc
            uri
            sector {
                id
                databaseId
                slug
                name
            }
            databaseId
            description
            id
            isRedSeal
            itaurl
            workBCURL
            name
            programTypeRegion {
                publicProgramTypeId
                regions
            }
            publicProgramTypeIds
            regions
            requirements
            sectorId
            slug
            schedules {
                isActive
                index
                id
                startDate
                endDate
                isCombinedDelivery
                isGapTraining
                isInSchool
                apprenticeLevel
                publicProgramTypeId
                programAppWebsite
                programFdnWebsite
                campus {
                    name
                    id
                }
                region {
                    id
                }
                institutionProgram {
                    name
                }
                institution {
                    id
                    name
                    shortName
                    databaseId
                    website
                }
                waitlistIndicator {
                    status
                }
            }
            publicProgramTypesJson {
                vocational
                youth
                foundation
                apprentice
            }
        }

        allInstitution {
            nodes {
                databaseId
                name
            }
        }
        regions: allRegion {
            nodes {
                name
                id
                databaseId
            }
        }
        # sector(id: { eq: $id }) {

        # }
        programs: allPublicProgram {
            nodes {
                uri
                sectorId
                name
                slug
            }
        }
        campus: allCampuse {
            nodes {
                databaseId
                name
                id
                address
            }
        }

        # programType: publicProgramType(databaseId: { eq: $programId }) {
        #   databaseId
        #   slug
        # }
    }
`;
